import React, { useState, useEffect } from 'react';
import { firestore } from './firebase'; // Import Firestore instance
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

function QuizzesViewer() {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const quizzesRef = collection(firestore, 'quizzesMetadata');
        const q = query(quizzesRef, orderBy('quizId', 'desc')); // Sorting by quizId in descending order
        const querySnapshot = await getDocs(q);
        
        const quizzesList = querySnapshot.docs.map(doc => doc.data());
        setQuizzes(quizzesList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
        setLoading(false);
      }
    };

    fetchQuizzes();
  }, []);

  const handleQuizClick = (quizId) => {
    navigate(`/quizpage?id=${quizId}`);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faHome} className="text-white text-2xl cursor-pointer" onClick={() => navigate('/landing')} />
        </div>
        <h1 className="text-xl font-bold">Quiznect - All Quizzes</h1>
        <div></div>
      </nav>

      <div className="w-full max-w-3xl mt-8 mb-8 bg-white p-6 rounded-lg shadow-lg text-left">
        <h2 className="text-2xl font-bold mb-6">Available Quizzes</h2>
        <ul className="quiz-list">
          {quizzes.map((quiz, index) => (
            <li 
              key={index} 
              className="quiz-item bg-white p-6 mb-6 rounded-lg shadow-lg cursor-pointer hover:bg-gray-100 transition-all" 
              onClick={() => handleQuizClick(quiz.quizId)} // Handle click to navigate to quiz page
            >
              <h3 className="text-xl font-semibold mb-2">{quiz.quizName.replace(/_/g, ' ')}</h3>
              <p className="mb-2"><strong>Topics:</strong> {quiz.tags.join(', ')}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QuizzesViewer;


/*
// Function to format the date in a more readable format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // You can customize the format here if needed
  }; */
// This doesn't work but just to keep it in mind if I want this later?
// <p><strong>Created At:</strong> {formatDate(quiz.createdAt)}</p> {/* Format date */}