import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faCheckCircle, faHome } from '@fortawesome/free-solid-svg-icons';

const TriviaSummaryScreen = () => {
  console.log("In TriviaSummaryScreen");
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [tourneyId, setTournamentId] = useState(null);
  const {
    correctCount,
    incorrectCount,
    completionPercentage,
    totalScore,
    totalPoints,
    totalBonusPoints,
    userAnswers,
    quizType,
    quizName,
    quizId,
    tournamentId,
  } = location.state || {};

  const passedDailyQuiz = quizType === 'dailyTrivia' && correctCount >= 3;
  const isSupportedCategory = [
    'dailyTrivia',
    'randomQuiz10Timed',
    'triviaArt',
    'triviaEntertainment',
    'triviaGeneralKnowledge',
    'triviaGeography',
    'triviaHistory',
    'triviaLiterature',
    'triviaScience',
    'triviaSports',
  ].includes(quizType);

  useEffect(() => {
    console.log("TriviaSummaryScreen - tournamentId " + tournamentId);
    if (tournamentId) {
      setTournamentId(tournamentId);
    } else {
      console.log('no tournamentId');
    }
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLoginRedirect = () => {
    if (window.confirm("You need to log in to save your scores and see leaderboards. Do you want to log in now?")) {
      navigate('/login');  // Adjust to your login route
    }
  };

  const handleGoBack = () => {
    navigate('/landing');  // Navigate to main page (adjust if necessary)
  };

  const handleGoBackToQuizPage = () => {
    if (quizId) {
      navigate(`/quizpage?id=${quizId}`);
    } else {
      console.error('quizId is not available');
      alert('Cannot navigate back to Quiz Page. quizId is missing.');
    }
  };
  
  const handleViewTournamentResults = () => {
    if (tournamentId) {
      navigate(`/tourney-results`, {
        state: {
          tournamentId: tourneyId,
          quizId: quizId,
          quizName: quizName,
        },
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        {/* Home Button on the Left */}
        <div className="flex items-center">
          <FontAwesomeIcon 
            icon={faHome} 
            className="text-white text-2xl cursor-pointer" 
            onClick={handleGoBack} 
            title="Home"
          />
        </div>
        <h1 className="text-xl font-bold">Trivia Summary</h1>
        <div className="flex items-center space-x-4">
          {/* Right side can be empty or include other icons if needed */}
        </div>
      </nav>

      {/* Centered Quiz Name and Type */}
      <div className="mt-4 mb-6 flex flex-col items-center">
        <button 
          onClick={handleGoBackToQuizPage} 
          className="bg-white text-purple-900 px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
          title="Back to Quiz Details"
        >
          {quizName.replace(/_/g, ' ')}
        </button>
        
        {/* Display Quiz Type */}
        <div className="mt-2 flex items-center">
          {quizType === 'Quiz10Tournament' && (
            <>
              <FontAwesomeIcon icon={faTrophy} className="text-yellow-400 text-2xl mr-2" />
              <span className="text-xl font-semibold">Tournament Mode</span>
            </>
          )}
          {quizType === 'randomQuiz10Timed' && (
            <span className="text-xl font-semibold">Random Quiz</span>
          )}
          {/* Add more conditions if you have other quiz types */}
        </div>
      </div>

      {/* Summary Content */}
      <div className="w-full max-w-3xl mb-8 bg-white p-6 rounded-lg shadow-lg text-center">
        <FontAwesomeIcon icon={faTrophy} size="3x" color="orange" />
        <p className="text-xl font-bold mt-4">Quiz Name: {quizName}</p>
        <p className="text-lg">Correct Answers: {correctCount}</p>
        <p className="text-lg">Incorrect Answers: {incorrectCount}</p>
        <p className="text-lg">Completion Percentage: {completionPercentage}%</p>
        <p className="text-lg">Total Points: {totalPoints}</p>
        <p className="text-lg">Bonus Points: {totalBonusPoints}</p>
        <p className="text-lg mb-4">Total Score: {totalScore}</p>

        {passedDailyQuiz && (
          <div className="flex items-center justify-center mb-4">
            <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
            <p className="text-lg text-green-600 ml-2">You passed today's daily quiz!</p>
          </div>
        )}

        {isSupportedCategory && (
          <button
            className="bg-purple-600 text-white px-4 py-2 rounded-lg mt-4 flex items-center justify-center gap-2 hover:bg-purple-700 transition-all"
            onClick={() => navigate('/leaderboard', { state: { quizType } })}
          >
            <FontAwesomeIcon icon={faTrophy} />
            View Leaderboard
          </button>
        )}

        {!user && (
          <div className="mt-6">
            <p>Your scores can be saved if you log in.</p>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg mt-2 hover:bg-blue-700 transition-all"
              onClick={handleLoginRedirect}
            >
              Log In
            </button>
          </div>
        )}
        {tourneyId && (
          <div className="mt-6">
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-lg mt-2 hover:bg-blue-700 transition-all"
            onClick={handleViewTournamentResults}
          >
            Tournament Scores
          </button>
        </div>

        )}

        <button
          className="bg-purple-600 text-white px-4 py-2 rounded-lg mt-6 hover:bg-purple-700 transition-all"
          onClick={handleGoBack}
        >
          Ok
        </button>
      </div>

      {/* Detailed Results */}
      <h2 className="text-2xl font-bold mt-8 mb-4">Detailed Results</h2>
      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg">
        {userAnswers.map((answer, index) => (
          <div key={index} className="mb-4">
            <p className="text-lg font-semibold">
              Question {answer.questionIndex + 1}:
            </p>
            <div
              className="text-base mb-2"
              dangerouslySetInnerHTML={{ __html: answer.question.replace(/\n/g, '<br/>') }}
            />
            <p className="text-base">
              Your Answer: {answer.selectedAnswer || 'No answer selected'}
            </p>
            <p className="text-base">
              Correct Answer: {answer.correctAnswer}
            </p>
            <p className={`text-base font-semibold ${answer.isCorrect ? 'text-green-600' : 'text-red-600'}`}>
              {answer.isCorrect ? 'Correct' : 'Incorrect'}
            </p>
            <hr className="my-4" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TriviaSummaryScreen;
