// QuizPage.js

import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore, auth, analytics  } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { logEvent } from 'firebase/analytics'; 
import LoadingSpinner from './LoadingSpinner';
import ScreenNameContext from './ScreenNameContext';
import { useAuthModal } from './contexts/AuthModalContext';

function QuizPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { openAuthModal } = useAuthModal();
  const [quizData, setQuizData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tournamentData, setTournamentData] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [isTournamentActive, setIsTournamentActive] = useState(false);
  const [hasCompletedTournament, setHasCompletedTournament] = useState(false);
  const { screenName, setScreenName } = useContext(ScreenNameContext);
  const [learningPlan, setLearningPlan] = useState([]);
  const [enhancedLearningPlan, setEnhancedLearningPlan] = useState([]);
  const [uploaded_learning_plan_path, setUploaded_learning_plan_path] = useState([]);
  const [finalLearningPlan, setFinalLearningPlan] = useState([]);
  const [user, setUser] = useState(null);

  const parseLearningPlan = (planString) => {
    console.log("in parseLearningPlan: " + planString);
    if (!planString || typeof planString !== 'string') return [];
    const milestones = planString.split('Milestone:').slice(1);
    console.log("in parseLearningPlan milestones: " + milestones);
    return milestones.map((milestone) => {
      const [milestoneTitle, topicsStr] = milestone.split('Topics:');
      console.log("in parseLearningPlan topicsStr: " + topicsStr);
      return {
        milestone: milestoneTitle?.trim() || 'No Milestone',
        topics: topicsStr ? topicsStr.trim().split(',').map(topic => topic.trim()) : []
      };
    });
  };

  const parseFinalLearningPlan = (plan) => {
    if (!plan || !Array.isArray(plan)) return [];
    return plan.map((item) => ({
      milestone: item.milestone,
      subCategory: item.subCategory,
      topic: item.topic,
      description: item.description,
      learningContent: item.learningContent
    }));
  };

  // Function to desanitize the quiz name
  const desanitizeQuizName = (quizName) => {
    return quizName.replace(/_/g, ' ');
  };

  useEffect(() => {
    // Set up the authentication state listener
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const quizId = queryParams.get('id');

    const fetchQuizData = async () => {
      if (quizId) {
        const quizDocRef = doc(firestore, 'quizzesMetadata', quizId);
        const quizDocSnap = await getDoc(quizDocRef);

        if (quizDocSnap.exists()) {
          const quiz = quizDocSnap.data();
          setQuizData(quiz);
          
          if (quiz.learningPlan) {
            //console.log("quiz.learningPlan : ", quiz.learningPlan);
            setLearningPlan(parseLearningPlan(quiz.learningPlan));
          }
          if (quiz.enhancedLearningPlan) {
            setEnhancedLearningPlan(quiz.enhancedLearningPlan);
          }

          if (quiz.uploaded_learning_plan_path) {
            console.log("quiz.uploaded_learning_plan_path:" + quiz.uploaded_learning_plan_path);
            let adjustedPath = quiz.uploaded_learning_plan_path;
            adjustedPath = adjustedPath.slice(0, -25) + 'finalLearningPlan.json';

            try {
              console.log("adjusted uploaded_learning_plan_path:", adjustedPath);
              const response = await fetch(`https://storage.googleapis.com/quiznect-quiz-storage-bucket/${adjustedPath}`);
              const data = await response.json();
              setFinalLearningPlan(parseFinalLearningPlan(data));
            } catch (error) {
              console.error('Error fetching uploaded_learning_plan_path data:', error);
              //alert('Failed to load quiz data. Please try again later.');
              //navigate('/landing');
            }
          } else {
            console.log(" quiz.uploaded_learning_plan_path NOT FOUND.");
          }

          // Check if the current user is the creator of the quiz

          const currentUser = auth.currentUser;

          if (currentUser) {
            console.log("QuizPage fetchQuizData currentUser.userEmail:" + currentUser.userEmail);
          } else {
            console.log("QuizPage fetchQuizData currentUser is null");
          }
          
          if (currentUser && currentUser.email === quiz.userEmail) {
            setIsCreator(true);
          }
          
          console.log("QuizPage fetchQuizData isCreator:" + isCreator);

          // Conditional Tournament Query
          let tournamentQuery;
          if (user) {
            // Authenticated users can see all tournaments
            tournamentQuery = query(
              collection(firestore, 'tournaments'),
              where('quizId', '==', quizId),
              where('status', '!=', 'completed')
            );
          } else {
            // Unauthenticated users can only see public tournaments
            tournamentQuery = query(
              collection(firestore, 'tournaments'),
              where('quizId', '==', quizId),
              where('status', '!=', 'completed'),
              where('public', '==', true)
            );
          }

          const tournamentQuerySnapshot = await getDocs(tournamentQuery);
          console.log("QuizPage fetchQuizData tournamentQuerySnapshot:" + tournamentQuerySnapshot);
          if (!tournamentQuerySnapshot.empty) {
            const activeTournament = tournamentQuerySnapshot.docs[0].data();
            setTournamentData({ ...activeTournament, id: tournamentQuerySnapshot.docs[0].id });
            console.log("QuizPage fetchQuizData activeTournament:" + activeTournament);

              
            console.log('tournamentQuerySnapshot - isTournamentActive:', isTournamentActive);

            if (activeTournament) {
                const localDate = new Date();
                const localDateTimeUTC = convertToUTC(localDate);
                const currentTime = handleDateConversion(localDateTimeUTC);
                const startTime = handleDateConversion(activeTournament.startTime);
                const endTime = handleDateConversion(activeTournament.endTime);
            
                console.log('Adjusted currentTime:', currentTime);
                console.log('Adjusted startTime:', startTime);
                console.log('Adjusted endTime:', endTime);
            
                if (startTime && endTime) {
                    let isTournamentActiveStatus = currentTime >= startTime && currentTime <= endTime;
                    setIsTournamentActive(isTournamentActiveStatus);
                }
              }

            // Check if the user has completed the tournament
            if (currentUser) {
              const userScoresRef = collection(firestore, 'userScores');
              const userTournamentScoreQuery = query(
                userScoresRef,
                where('userUid', '==', currentUser.uid),
                where('quizId', '==', quiz.quizId),
                where('tournamentId', '==', tournamentQuerySnapshot.docs[0].id)
              );
              const userTournamentScoreSnapshot = await getDocs(userTournamentScoreQuery);
              if (!userTournamentScoreSnapshot.empty) {
                setHasCompletedTournament(true);
              }
            }
          } else {
            console.log("QuizPage fetchQuizData tournamentQuerySnapshot is Empty:");
          }
        } else {
          console.log('No such document!');
        }

        setLoading(false);
      }
    };

    fetchQuizData();
  }, [location]);

  const handleQuizLaunch = () => {
    if (quizData) {
      if (!user) {
        openAuthModal('You need to be logged in to take the quiz.');
        return;
      }
      navigate(
        `/play-quiz?quizId=${quizData.quizId}&quizFile=${encodeURIComponent(quizData.storagePath)}&quizName=${encodeURIComponent(desanitizeQuizName(quizData.quizName))}&quizType=randomQuiz10Timed&categoryName=${encodeURIComponent(quizData.tags.join(', ') || '')}`
      );
      if (analytics) {
        logEvent(analytics, 'quiz_launch', {
          quiz_id: quizData.quizId,
          quiz_name: quizData.quizName,
          user_uid: auth.currentUser.uid,
        });
      }
    }
  };
  
  const handleTagClick = (tag) => {
    if (quizData && quizData.quizId) {
        console.log(" quizId: quizData.quizId: " + quizData.quizId);
        navigate(`/play-quiz?quizId=${quizData.quizId}&quizFile=${encodeURIComponent(quizData.storagePath)}&quizName=${encodeURIComponent(desanitizeQuizName(quizData.quizName))}&quizType=randomQuiz10Timed&tagFilter=${encodeURIComponent(tag)}`);
        if (analytics) {
          logEvent(analytics, 'tag_click', {
            tag: tag,
            quiz_id: quizData.quizId,
            user_uid: auth.currentUser.uid,
          });
        }
    }
  };

  const handleCreateOrEditTournament = () => {
    if (tournamentData) {
      navigate(`/tournament-curator`, {
        state: { quizId: quizData.quizId, tournamentId: tournamentData.id },
      });
    } else if (quizData) {
      navigate(`/tournament-curator`, {
        state: { quizId: quizData.quizId },
      });
      // Log create tournament
      if (analytics) {
        logEvent(analytics, 'create_tournament_click', {
          quiz_id: quizData.quizId,
          user_uid: auth.currentUser.uid,
        });
      }
    }
  };

  const handleDeleteTournament = async () => {
    if (window.confirm('Are you sure you want to delete this tournament? This action cannot be undone.')) {
      try {
        await deleteDoc(doc(firestore, 'tournaments', tournamentData.id));
        setTournamentData(null);
        setIsTournamentActive(false);
        alert('Tournament deleted successfully.');
      } catch (error) {
        console.error('Error deleting tournament:', error);
        alert('Failed to delete tournament. Please try again.');
      }
    }
  };

  const handleEditQuiz = () => {
    if (quizData) {
      navigate(`/quiz-curator`, {
        state: { quizId: quizData.quizId },
      });
      if (analytics) {
        logEvent(analytics, 'edit_quiz_click', {
          quiz_id: quizData.quizId,
          user_uid: auth.currentUser.uid,
        });
      }
    }
  };

  const handlePlayTournament = () => {
    if (quizData && tournamentData) {
      console.log(" tournamentData.id: " + tournamentData.id);
  
      navigate(
        `/play-quiz?quizId=${quizData.quizId}&quizFile=${encodeURIComponent(quizData.storagePath)}&quizName=${encodeURIComponent(desanitizeQuizName(quizData.quizName))}&quizType=Quiz10Tournament&categoryName=${encodeURIComponent(quizData.tags.join(', ') || '')}&tournamentId=${tournamentData.id}`
      );
      if (analytics) {
        logEvent(analytics, 'play_tournament_click', {
          tournament_id: tournamentData.id,
          quiz_id: quizData.quizId,
          user_uid: auth.currentUser.uid,
        });
      }
    }
  };
  

  const handleViewTournamentResults = () => {
    if (tournamentData) {
      navigate(`/tourney-results`, {
        state: {
          tournamentId: tournamentData.id,
          quizId: quizData.quizId,
          quizName: desanitizeQuizName(quizData.quizName),
        },
      });
      if (analytics) {
        logEvent(analytics, 'view_tournament_results_click', {
          tournament_id: tournamentData.id,
          quiz_id: quizData.quizId,
          user_uid: auth.currentUser.uid,
        });
      }
    }
  };

  // Function to convert Firestore timestamp to formatted date string
  const handleDateConversion = (date) => {
    const utcDate = date instanceof Date ? date : new Date(date.toDate ? date.toDate() : date);

    return utcDate.toLocaleString('en-US', {
      timeZone: 'UTC', // Adjust if needed
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const convertToUTC = (localDateTimeString) => {
    const localDate = new Date(localDateTimeString);
    return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000); // Ensure UTC
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!quizData) {
    return <div>Quiz not found!</div>;
  }

  function parseTextWithLinks(text) {
    if (!text) return null;

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            className="underline text-blue-500 hover:text-blue-700"
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      }
      return part;
    });
  }

  const renderLearningPlan = () => {
    if (!Array.isArray(learningPlan) || learningPlan.length === 0) {
      return <p>No learning plan available.</p>;
    }

    return learningPlan.map((milestone, index) => {
      const enhancedInfo = enhancedLearningPlan.find(enhancedMilestone => enhancedMilestone.milestone === milestone.milestone);
      
      return (
        <div key={index} className="mb-6 p-4 border-l-4 border-purple-500 bg-gray-50 rounded-lg shadow-sm text-left">
          <h3 className="text-lg font-bold">{milestone.milestone}</h3>
          
          {milestone.topics && milestone.topics.length > 0 && (
            <ul className="mt-2 text-sm text-gray-700 list-disc pl-5">
              {milestone.topics.map((topic, topicIndex) => (
                <li key={topicIndex}>{topic}</li>
              ))}
            </ul>
          )}

          {enhancedInfo && (
            <>
              {enhancedInfo.explanation && (
                <p className="mt-2 text-sm text-gray-700">
                  {enhancedInfo.explanation}
                </p>
              )}

              {enhancedInfo.url && (
                <a href={enhancedInfo.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                  {enhancedInfo.url.replace(/^https?:\/\//, '')}
                </a>
              )}
            </>
          )}
        </div>
      );
    });
  };

  // Helper function to capitalize strings
  const capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderFinalLearningPlan = () => {
    if (!Array.isArray(finalLearningPlan) || finalLearningPlan.length === 0) {
      return <p>No final learning plan available.</p>;
    }

    const renderContent = (content) => {
      if (Array.isArray(content)) {
        return (
          <div className="ml-6 mb-2">
            {content.map((item, idx) => (
              <span key={idx} className="inline-block mr-4 bg-white p-2 rounded-lg border border-gray-200">
                {typeof item === 'object' && item !== null ? renderContent(item) : <span className="text-gray-700">{String(item)}</span>}
              </span>
            ))}
          </div>
        );
      } else if (typeof content === 'object' && content !== null) {
        return Object.entries(content).map(([key, value], idx) => {
          // Check if the key matches the pattern for a number followed by a colon
          if (/^\d+:$/.test(key)) {
            return null; // Skip rendering this key-value pair
          }

          return (
            <div key={idx} className="ml-4 mb-2">
              <span className="font-semibold text-gray-800">{capitalize(key.replace(/_/g, ' '))}:</span>
              {typeof value === 'object' ? renderContent(value) : <span className="ml-2 text-gray-700">{String(value)}</span>}
            </div>
          );
        });
      } else {
        return <span className="text-gray-700">{String(content)}</span>;
      }
    };

    return finalLearningPlan.map((item, index) => (
      <div key={index} className="mb-6 p-6 border-l-4 border-blue-500 bg-gray-50 rounded-lg shadow-lg text-left w-full max-w-6xl">
        <h3 className="text-2xl font-bold mb-4">{capitalize(item.milestone)}</h3>
        {item.subCategory && <h4 className="text-xl font-semibold mb-2">{capitalize(item.subCategory)}</h4>}
        {item.topic && <p className="text-lg font-semibold text-gray-800 mb-2">Topic: {capitalize(item.topic)}</p>}
        {item.description && <p className="text-base text-gray-700 mb-4">{item.description}</p>}

        {item.learningContent && (
          <div className="mt-4 text-base text-gray-700">
            {Object.entries(item.learningContent).map(([key, value], idx) => (
              <div key={idx} className="mb-6">
                <h5 className="font-bold text-lg mb-2">{capitalize(key.replace(/_/g, ' '))}</h5>
                {renderContent(value)}
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

    const AuthBanner = () => (
    <div
      className="w-full bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 mb-4 rounded relative flex flex-col sm:flex-row justify-between items-center"
      role="alert"
    >
      <span className="block sm:inline mb-2 sm:mb-0">
        Join us to access more features! Please log in or sign up.
      </span>
      <div className="flex space-x-2">
        <button
          onClick={() => navigate('/', { state: { mode: 'login' } })}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Log In
        </button>
        <button
          onClick={() => navigate('/', { state: { mode: 'signup' } })}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Sign Up
        </button>
      </div>
    </div>
  );


  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      <nav
        className="w-full text-white p-4 flex justify-between items-center"
        style={{ backgroundColor: '#9C27B0' }}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faHome}
            className="text-white text-2xl cursor-pointer"
            onClick={() => navigate('/landing')}
          />
        </div>
        <h1 className="text-xl font-bold">Quiznect</h1>
        <div></div>
      </nav>

      <div className="relative mb-12 mt-8">
        <h2 className="text-3xl  text-gray-900 bg-gray-200 p-6 rounded-lg shadow-lg">
          {desanitizeQuizName(quizData.quizName)}
        </h2>
       
      </div>

      {/* Authentication Banner */}
      {!user && <AuthBanner />}

      {isTournamentActive && !hasCompletedTournament && (
        <button
          onClick={handlePlayTournament}
          className="mb-8 w-full max-w-md bg-green-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-blue-700"
        >
          Play Tournament!
        </button>
      )}

      {hasCompletedTournament && (
        <div className="mb-8 text-green-600 text-xl font-bold">
          You have completed this tournament!
        </div>
      )}

      <button
        onClick={handleQuizLaunch}
        className="mb-8 w-full max-w-xs md:max-w-md bg-green-600 text-white p-4 rounded-lg text-lg font-bold transition-all hover:shadow-lg hover:bg-green-700 mx-auto"
      >
        Play Quiz
      </button>


      {quizData.curratorInfo && quizData.curratorInfo.length > 0 && (
        <div className="mb-4 bg-gray-100 p-4 rounded-lg shadow-lg mt-6">
          <p className="font-semibold text-gray-500">
            {parseTextWithLinks(quizData.curratorInfo)}
          </p>
        </div>
      )}

      <div className="w-full max-w-3xl mb-8 bg-white p-6 rounded-lg shadow-lg text-left">
        <h3 className="text-2xl font-semibold mb-4">Quiz Details</h3>
        {quizData.questionCount && (<p className="mb-4">
          <strong>Total Questions:</strong> {quizData.questionCount}
        </p>) }
        <p className="mb-4">
        <strong>Topics:</strong>{' '}
            {quizData.tags.map((tag, index) => (
                <span key={index}>
                <a
                    href="#"
                    onClick={() => handleTagClick(tag)}
                    className="text-green-900 font-semibold hover:underline"
                >
                    {tag}
                </a>
                {index < quizData.tags.length - 1 ? ', ' : ''}
                </span>
            ))}
        </p>

        {isCreator && (
          <button
            onClick={handleEditQuiz}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
          >
            Edit Quiz
          </button>
        )}

        {tournamentData ? (
          <div className="mt-8">
            {isTournamentActive ? (
                <div className="mt-4 text-green-600 text-2xl font-bold">
                    Tournament in Progress!
                </div>
                ) : (<h3 className="text-xl font-semibold text-gray-900">Tournament</h3>)
            }
            {tournamentData.tourneyInfo && tournamentData.tourneyInfo.length > 0 && (
              <p className="mt-2 mb-4 font-semibold text-gray-500">
                {parseTextWithLinks(tournamentData.tourneyInfo)}
              </p>
            )}
            <p className="mb-4">Start Time: {handleDateConversion(tournamentData.startTime)}</p>
            <p className="mb-4">End Time: {handleDateConversion(tournamentData.endTime)}</p>

            <div className="mt-4 flex space-x-4">
              {isCreator && (
                isTournamentActive ? (
                  <button
                    onClick={handleDeleteTournament}
                    className="bg-red-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-700 transition duration-300"
                  >
                    Delete Tournament
                  </button>
                ) : (
                  <button
                    onClick={handleCreateOrEditTournament}
                    className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
                  >
                    Edit Tournament
                  </button>
                )
              )}
              <button
                onClick={handleViewTournamentResults}
                className="bg-purple-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
              >
                View Tournament Results
              </button>
            </div>
          </div>
        ) : (
          isCreator && (
            <div className="mt-8">
              <button
                onClick={handleCreateOrEditTournament}
                className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
              >
                Create Tournament
              </button>
            </div>
          )
        )}
      </div>
      {/* New Final Learning Plan Section */}
      <div className="w-full max-w-6xl mb-8">
        <h3 className="text-2xl font-semibold mb-4 ml-4 ">Important Info:</h3>
        {renderFinalLearningPlan()}
      </div>

      {/* Add Learning Plan section */}
      <div className="w-full max-w-3xl mb-8">
        <h3 className="text-2xl font-semibold mb-4">Learning Plan</h3>
        {renderLearningPlan()}
      </div>



    </div>
  );
}

export default QuizPage;





/*

<p className="mb-4">{quizData.curratorInfo}</p>

<p><strong>Created:</strong> {quizData.createdAt}</p>

 <p><strong>Popularity:</strong> {quizData.popularity}</p>
        <p><strong>Public:</strong> {quizData.isPublic ? 'Yes' : 'No'}</p>
        */


        /*
            // Determine if the tournament is currently active
            const currentTime = new Date();
            const startDateTime = activeTournament.startTime.toDate();
            console.log(' startDateTime:', startDateTime);
            const endDateTime = activeTournament.endTime.toDate();
            console.log(' endDateTime:', endDateTime);
            if (currentTime >= startDateTime && currentTime <= endDateTime) {  
              setIsTournamentActive(true);
            }*/