// PlayQuiz.js
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHome, faTrophy, faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import ScreenNameContext from './ScreenNameContext';
import LoadingSpinner from './LoadingSpinner';
import { useAuthModal } from './contexts/AuthModalContext'; 

function PlayQuiz() {
  const [quizData, setQuizData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [sessionCorrectCount, setSessionCorrectCount] = useState(0);
  const [sessionIncorrectCount, setSessionIncorrectCount] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [timer, setTimer] = useState(25); // 25 seconds timer
  const navigate = useNavigate();
  const location = useLocation();
  const { openAuthModal } = useAuthModal(); 
  const [selectedQuizId, setQuizId] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [quizType, setQuizType] = useState('');
  const [userId, setUserId] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const { screenName } = useContext(ScreenNameContext);
  const [tagFilter, setTagFilter] = useState(null);

  // FNV-1a hash function
  const fnv1aHash = (str) => {
    let hash = 0x811c9dc5;
    for (let i = 0; i < str.length; i++) {
      hash ^= str.charCodeAt(i);
      hash = Math.imul(hash, 0x01000193);
    }
    return hash >>> 0;
  };

  // Seeded random number generator (Mulberry32)
  function mulberry32(a) {
    return function() {
      let t = a += 0x6D2B79F5;
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }
  }

  // Seeded shuffle function
  function shuffleArraySeed(array, seed) {
    const random = mulberry32(seed);
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(random() * currentIndex);
      currentIndex -= 1;

      // Swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  useEffect(() => {
    const loadQuizData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const quizFile = searchParams.get('quizFile');
      const quizName = searchParams.get('quizName');
      const quizType = searchParams.get('quizType');
      const quizId = searchParams.get('quizId');
      const tagFilterParam = searchParams.get('tagFilter');
      const tournamentIdParam = searchParams.get('tournamentId');

      if (!quizId) {
        console.error('quizId is missing in URL parameters.');
        alert('Cannot load quiz without a valid quizId.');
        navigate('/landing');
        return;
      }

      setTagFilter(tagFilterParam);

      // Get current user
      const currentUser = auth.currentUser;
      if (!currentUser) {
        openAuthModal('You need to be logged in to take the quiz.');
        return;
      }

      const userId = currentUser.uid;
      setUserId(userId);
      setQuizId(quizId);
      setQuizType(quizType);
      setTournamentId(tournamentIdParam);

      try {
        console.log("Loading quiz data from:", quizFile);
        const response = await fetch(`https://storage.googleapis.com/quiznect-quiz-storage-bucket/${quizFile}`);
        const data = await response.json();

        let questions = data.questions;

        // Filter questions by tag if tagFilter is provided
        if (tagFilterParam) {
          questions = questions.filter((question) => question.tags.includes(tagFilterParam));
        }

        if (quizType === 'randomQuiz10Timed' && data.questions) {
          questions = data.questions.sort(() => Math.random() - 0.5).slice(0, 10);
        } else if (quizType === 'Quiz10Tournament' && data.questions) {
          const seed = fnv1aHash(tournamentIdParam);
          const allQuestions = data.questions.slice();
          const shuffledQuestions = shuffleArraySeed(allQuestions, seed);
          questions = shuffledQuestions.slice(0, 10);
        } else {
          questions = data.questions;
        }

        setQuizData({ questions, quizName });

        // Check Firestore for existing quiz state
        const quizStateDocRef = doc(firestore, 'userQuizStates', `${userId}_${quizId}_${quizType}`);
        const quizStateDoc = await getDoc(quizStateDocRef);

        if (quizStateDoc.exists()) {
          // Quiz state exists, load it
          const savedState = quizStateDoc.data();

          // Update state with saved values
          setCurrentQuestionIndex(savedState.currentQuestionIndex);
          setUserAnswers(savedState.userAnswers);
          setSessionCorrectCount(savedState.sessionCorrectCount);
          setSessionIncorrectCount(savedState.sessionIncorrectCount);
        } else {
          // No existing state, create initial state in Firestore
          await setDoc(quizStateDocRef, {
            userId,
            quizId,
            quizType,
            quizName,
            screenName,
            currentQuestionIndex: 0,
            userAnswers: [],
            sessionCorrectCount: 0,
            sessionIncorrectCount: 0,
            timestamp: serverTimestamp(),
          });
        }
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        alert('Failed to load quiz data. Please try again later.');
        navigate('/landing');
      }
    };

    loadQuizData();
  }, [location.search, navigate]);

  const quizStateDocRef = useMemo(() => {
    if (userId && selectedQuizId && quizType) {
      return doc(firestore, 'userQuizStates', `${userId}_${selectedQuizId}_${quizType}`);
    } else {
      return null;
    }
  }, [userId, selectedQuizId, quizType]);

  // Timer effect
  useEffect(() => {
    let interval = null;
    if (timer > 0 && !selectedAnswer) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && !selectedAnswer) {
      // Time's up, mark as incorrect
      handleAnswerSelection(null);
    }
    return () => clearInterval(interval);
  }, [timer, selectedAnswer]);

  useEffect(() => {
    if (quizData) {
      const question = quizData.questions[currentQuestionIndex];
      const correctAnswer = question.correctAnswer;
      const options = question.options;

      // Shuffle options with the helper function
      const shuffled = shuffleOptions(options, correctAnswer);

      setShuffledOptions(shuffled);
      setCorrectAnswer(correctAnswer);
    }
  }, [quizData, currentQuestionIndex]);

  // Existing shuffleArray function (Fisher-Yates Shuffle)
  const shuffleArray = (array) => {
    // Make a copy to avoid mutating the original array
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // New helper function to handle shuffling with "All of the above" at the bottom
  const shuffleOptions = (options, correctAnswer) => {
    if (correctAnswer === "All of the above") {
      // Separate "All of the above" from the other options
      const allOfTheAboveOption = options.find(option => option === "All of the above");
      const otherOptions = options.filter(option => option !== "All of the above");
      
      // Shuffle the other options
      const shuffledOtherOptions = shuffleArray(otherOptions);
      
      // Append "All of the above" at the end
      return [...shuffledOtherOptions, allOfTheAboveOption];
    } else {
      // Shuffle all options normally
      return shuffleArray(options);
    }
  };

  const handleAnswerSelection = async (answer) => {
    if (selectedAnswer) return;

    setSelectedAnswer(answer);
    const question = quizData.questions[currentQuestionIndex];
    const answerIsCorrect = answer === question.correctAnswer;
    setIsCorrect(answerIsCorrect);

    // Update userAnswers
    const updatedUserAnswers = [
      ...userAnswers,
      {
        questionIndex: currentQuestionIndex,
        question: question.question,
        selectedAnswer: answer,
        correctAnswer: question.correctAnswer,
        isCorrect: answerIsCorrect,
      },
    ];
    setUserAnswers(updatedUserAnswers);

    const newCorrectCount = answerIsCorrect ? sessionCorrectCount + 1 : sessionCorrectCount;
    const newIncorrectCount = !answerIsCorrect ? sessionIncorrectCount + 1 : sessionIncorrectCount;

    setSessionCorrectCount(newCorrectCount);
    setSessionIncorrectCount(newIncorrectCount);

    // Update Firestore
    if (quizStateDocRef) {
      await updateDoc(quizStateDocRef, {
        currentQuestionIndex,
        userAnswers: updatedUserAnswers,
        sessionCorrectCount: newCorrectCount,
        sessionIncorrectCount: newIncorrectCount,
        timestamp: serverTimestamp(),
      });
    }
  };

  const handleNextQuestion = async () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      const newQuestionIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(newQuestionIndex);
      setSelectedAnswer(null);
      setIsCorrect(null);
      setTimer(25); // Reset the timer for the next question

      // Update Firestore
      if (quizStateDocRef) {
        await updateDoc(quizStateDocRef, {
          currentQuestionIndex: newQuestionIndex,
          timestamp: serverTimestamp(),
        });
      }
    } else {
      handleExitQuiz();
    }
  };

  const handleExitQuiz = async () => {
    const totalPoints = sessionCorrectCount * 10;
    const totalScore = totalPoints;

    // Check if user is logged in
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userId = currentUser.uid;
      const userEmail = currentUser.email;
      console.log('In handleExitQuiz - tournamentId: ' + tournamentId);
      try {
        const userScoresRef = collection(firestore, 'userScores');
        await addDoc(userScoresRef, {
          userId: userId,
          userEmail: userEmail,
          screenName: screenName,
          quizId: selectedQuizId || quizData.quizId || '',
          quizName: quizData.quizName,
          quizType: quizType,
          score: totalScore,
          correctCount: sessionCorrectCount,
          incorrectCount: sessionIncorrectCount,
          timestamp: serverTimestamp(),
          tournamentId: tournamentId,
        });
        console.log('Score saved successfully.');

        // Delete quiz state from Firestore
        if (quizStateDocRef) {
          await deleteDoc(quizStateDocRef);
        }
      } catch (error) {
        console.error('Error saving score:', error);
      }
    } else {
      console.log('User not logged in. Score not saved.');
    }

    navigate('/trivia-summary', {
        state: {
          correctCount: sessionCorrectCount,
          incorrectCount: sessionIncorrectCount,
          completionPercentage,
          totalScore,
          totalPoints, // Add this
          totalBonusPoints: 0, // Adjust if you have bonus points logic
          userAnswers,
          quizType,
          quizName: quizData.quizName, // Add this
          quizId: selectedQuizId, // Add this
          tournamentId: tournamentId,
        },
      });
  };

  const handleGoHome = () => {
    navigate('/landing');
  };

  const handleGoBackToQuizPage = () => {
    if (selectedQuizId) {
      navigate(`/quizpage?id=${selectedQuizId}`);
    } else {
      console.error('quizId is not available');
      alert('Cannot navigate back to Quiz Page. quizId is missing.');
    }
  };

  if (!quizData || quizData.questions.length === 0) {
    return <LoadingSpinner />;
  }

  const handleFlagQuestion = () => {
    navigate('/q-feedback', {
      state: {
        quizFile: quizData.quizFile, // Changed from location.state.quizFile
        questionIndex: currentQuestionIndex,
        questionText: quizData.questions[currentQuestionIndex].question,
        quizState: {
          currentQuestionIndex,
          selectedAnswer,
          sessionCorrectCount,
          sessionIncorrectCount,
          completionPercentage,
          shuffledOptions,
          quizData,
          timer,
        },
      },
    });
  };

  const question = quizData.questions[currentQuestionIndex];
  const explanationHtml = selectedAnswer ? question.explanation : '';

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        {/* Home Button on the Left */}
        <div className="flex items-center">
          <FontAwesomeIcon 
            icon={faHome} 
            className="text-white text-2xl cursor-pointer" 
            onClick={handleGoHome} 
            title="Home"
          />
        </div>
        <h1 className="text-xl font-bold">
          {quizType === 'Quiz10Tournament' && (
            <FontAwesomeIcon icon={faTrophy} className="text-yellow-400 text-2xl mr-2" />
          )}
          Question {currentQuestionIndex + 1} of {quizData.questions.length}
        </h1>
        <div className="flex items-center space-x-4">
          {selectedAnswer && (
            <button 
              className="bg-[#9C27B0] text-white p-2 rounded-lg cursor-pointer" 
              onClick={handleFlagQuestion}
              title="Flag this question"
            >
              <FontAwesomeIcon icon={faFlag} />
            </button>
          )}
        </div>
      </nav>

      {/* Centered Quiz Name Button with Back Arrow */}
      <div className="mt-4 mb-6 flex items-center justify-center">
        <button 
            onClick={handleGoBackToQuizPage} 
            className="flex items-center bg-white text-purple-900 px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300"
            title="Back to Quiz Details"
        >
            <FontAwesomeIcon 
            icon={faArrowLeft} 
            className="text-purple-900 text-2xl cursor-pointer mr-4" // Adjusted spacing with "mr-4"
            title="Back"
            />
            <span className="ml-2">{quizData.quizName.replace(/_/g, ' ')}</span> {/* Adjusted spacing */}
        </button>
      </div>

      {tagFilter && (
        <div className="mt-2 mb-4 text-lg font-semibold text-purple-900">
          Topic: {tagFilter}
        </div>
      )}

      {/* Quiz Question */}
      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg">
        <div 
          className="text-lg font-semibold text-center mb-4" 
          dangerouslySetInnerHTML={{ __html: question.question.replace(/\n/g, '<br/>') }} 
        />

        <div className="grid gap-4">
          {shuffledOptions.map((option, index) => (
            <div
              key={index}
              className={`bg-[#9C27B0] text-white p-4 rounded-lg text-center cursor-pointer ${
                selectedAnswer === option ? (isCorrect ? 'bg-green-600' : 'bg-red-600') : 'hover:bg-purple-700'
              } transition duration-300`}
              onClick={() => handleAnswerSelection(option)}
            >
              <div dangerouslySetInnerHTML={{ __html: option.replace(/\n/g, '<br/>') }} />
            </div>
          ))}
        </div>

        {selectedAnswer && (
          <>
            <div className="flex justify-center mt-6">
              {currentQuestionIndex < quizData.questions.length - 1 ? (
                <button 
                  className="bg-[#9C27B0] text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300" 
                  onClick={handleNextQuestion}
                >
                  Next
                </button>
              ) : (
                <button 
                  className="bg-[#9C27B0] text-white px-6 py-3 rounded-lg shadow-md hover:bg-purple-700 transition duration-300" 
                  onClick={handleExitQuiz}
                >
                  Finish Quiz
                </button>
              )}
            </div>
            {explanationHtml && explanationHtml.length > 0 && (
              <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-6">
                <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
              </div>
            )}
            {question.url && (
              <div className="text-center mt-4">
                <a 
                  href={question.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-blue-600 underline"
                >
                  {question.url.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PlayQuiz;






/*
<div className="flex items-center space-x-4">
{!selectedAnswer && (
  <div className="text-center mt-4">
      <span className="text-xl font-bold">
          {timer}
      </span>
  </div>
)}
</div>



 <div className="mt-2 flex items-center">
        {quizType === 'Quiz10Tournament' && (
        <>
            <FontAwesomeIcon icon={faTrophy} className="text-yellow-400 text-2xl mr-2" />
            <span className="text-xl font-semibold">Tournament</span>
        </>
        )}
        {quizType === 'randomQuiz10Timed' && (
            <span className="text-xl font-semibold">Random Quiz</span>
        )}
        </div>
*/
