import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc, setDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { auth, firestore } from './firebase';  // Make sure you have Firebase config set up
import bannedWords from './utils/engBadWords.json';  // Import the banned words list
import './ProfileSettingsPage.css';

const MAX_SCREEN_NAME_LENGTH = 24; // Set max screen name length

const ProfileSettingsPage = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [screenName, setScreenName] = useState('');
  const [newScreenName, setNewScreenName] = useState('');
  const [editingScreenName, setEditingScreenName] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // Error message for screen name validation and uniqueness
  const [errorToggle, setErrorToggle] = useState(false); // Toggle error messages for better feedback

  const navigate = useNavigate();

  // Fetch user info on page load
  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);

        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setScreenName(userData.screenName || '');
          if (!userData.screenName) {
            setEditingScreenName(true);
          }
        } else {
          setEditingScreenName(true);
        }
      } else {
        navigate('/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  const checkScreenNameUniqueness = async () => {
    const q = query(collection(firestore, 'users'), where('screenName', '==', newScreenName));
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty; // Return true if no existing user with this screen name
  };

  const containsBannedWords = (text) => {
    const textLower = text.toLowerCase();
    return bannedWords.some(word => textLower.includes(word));
  };

  const handleSaveScreenName = async () => {
    if (!newScreenName) {
      setErrorMessage('Please enter a screen name.');
      return;
    }

    if (newScreenName.length > MAX_SCREEN_NAME_LENGTH) {
      setErrorMessage(`Screen name cannot exceed ${MAX_SCREEN_NAME_LENGTH} characters.`);
      return;
    }

    if (containsBannedWords(newScreenName)) {
      setErrorMessage('Screen name contains inappropriate words. Please choose another.');
      return;
    }

    setLoading(true); // Set loading state
    try {
      const isUnique = await checkScreenNameUniqueness();
      if (!isUnique) {
        // Toggle between two error messages for feedback
        setErrorToggle(!errorToggle);
        setErrorMessage(errorToggle ? 'Screen name is already taken. Please try another.' : 'That screen name is taken, please choose another.');
        setLoading(false); // Reset the loading state
        return;
      }

      const currentUser = auth.currentUser;
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      await setDoc(userDocRef, { screenName: newScreenName }, { merge: true });
      setScreenName(newScreenName);
      setNewScreenName('');
      setEditingScreenName(false);
    } catch (error) {
      console.error('Error saving screen name:', error);
      setErrorMessage('Error saving screen name. Please try again.');
    } finally {
      setLoading(false); // Reset the loading state
    }
  };

  const handleEditScreenName = () => {
    setNewScreenName(screenName);
    setEditingScreenName(true);
  };

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const openUserAgreement = () => {
    window.open('https://quiznect.com', '_blank');
  };

  const sendEmail = () => {
    const email = 'quiznect@gmail.com';
    const subject = 'Contact Support';
    const body = 'Hi there,\n\nI need help with...';
    const url = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = url;
  };

  return (
    <div className="profile-settings-container">
      <div className="profile-header">
        <button onClick={() => navigate('/landing')} className="home-button">
          <FontAwesomeIcon icon={faHome} />
        </button>
        <h1 className="profile-title">Profile</h1>
      </div>
      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div className="profile-info">
          <div className="profile-icon">
            <FontAwesomeIcon icon={faUser} size="5x" color="#9C27B0" />
          </div>
          <h2 className="profile-name">{screenName || 'Set your screen name'}</h2>
          <p className="profile-email">{user?.email}</p>

          {editingScreenName ? (
            <div className="screen-name-form">
              <input
                type="text"
                className="screen-name-input"
                placeholder="Enter your screen name"
                value={newScreenName}
                maxLength={MAX_SCREEN_NAME_LENGTH} // Limit input length to 24 characters
                onChange={(e) => setNewScreenName(e.target.value)}
              />
              {errorMessage && (
                <p className="error-message" style={{ marginTop: '10px', color: 'red' }}>
                  {errorMessage}
                </p>
              )}
              <button className="profile-button" onClick={handleSaveScreenName} disabled={loading}>
                {loading ? 'Saving...' : 'Save Screen Name'}
              </button>
              <button className="profile-button" onClick={() => setEditingScreenName(false)}>
                Cancel
              </button>
            </div>
          ) : (
            <div className="edit-logout-scores">
              <button className="profile-button" onClick={handleEditScreenName}>
                Edit Screen Name
              </button>
              <button className="profile-button" onClick={handleLogout}>
                Log Out
              </button>
              <button className="link-button" onClick={() => navigate('/user-scores')}>
                View Scores
              </button>
            </div>
          )}
        </div>
      )}



      <div className="links-container">
        <button className="link-button" onClick={openUserAgreement}>
          User Agreement
        </button>
        <button className="link-button" onClick={sendEmail}>
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default ProfileSettingsPage;




/* 

const version = "1.0.3"; // Replace with actual version from your config
  const buildNumber = "17"; // Replace with actual build number from your config



          <button className="profile-button profile-danger">Delete Account</button>



           <div className="version-container">
        <p>Version: {version}</p>
        <p>Build: {buildNumber}</p>
      </div>


          */
