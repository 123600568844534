// App.js

import React, { useEffect, useState } from 'react';
import './App.css'; // Ensure Tailwind directives are included in this CSS file
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import HomePage from './HomePage';
import LandingPage from './LandingPage';
import LearningFocusPage from './LearningFocusPage';
import TriviaScreen from './TriviaScreen';
import TriviaSummaryScreen from './TriviaSummaryScreen';
import QFeedbackPage from './QFeedbackPage';
import QuizzesListLocal from './QuizzesListLocal';
import TriviaListLocal from './TriviaListLocal';
import ProfileSettingsPage from './ProfileSettingsPage';
import CreateScreenName from './CreateScreenName';
import QuizGenerationProgressPage from './QuizGenerationProgressPage';
import QuizPage from './QuizPage';
import PlayQuiz from './PlayQuiz';
import QuizzesViewer from './QuizzesViewer';
import MyQuizzesViewer from './MyQuizzesViewer';
import UserScores from './UserScores';
import TournamentCurator from './TournamentCurator';
import QuizCurator from './QuizCurator';
import TourneyResults from './TourneyResults';
import ScreenNameContext from './ScreenNameContext';  
import { AuthModalProvider } from './contexts/AuthModalContext';

// Component to handle page view tracking
function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    // Log page view event
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title: document.title,
    });
  }, [location]);

  return null;
}

function App() {
  const [screenName, setScreenName] = useState('');

  return (
    <Router>
      <AuthModalProvider>
        <ScreenNameContext.Provider value={{ screenName, setScreenName }}>
          <AnalyticsTracker />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/learning-focus" element={<LearningFocusPage />} />
            <Route path="/trivia-screen" element={<TriviaScreen />} />
            <Route path="/trivia-summary" element={<TriviaSummaryScreen />} />
            <Route path="/q-feedback" element={<QFeedbackPage />} />
            <Route path="/quizzes-free" element={<QuizzesListLocal />} />
            <Route path="/trivia" element={<TriviaListLocal />} />
            <Route path="/profile" element={<ProfileSettingsPage />} />
            <Route path="/create-screen-name" element={<CreateScreenName />} />
            <Route path="/quiz-generation-progress" element={<QuizGenerationProgressPage />} />
            <Route path="/quizpage" element={<QuizPage />} />
            <Route path="/play-quiz" element={<PlayQuiz />} />
            <Route path="/quizzes-viewer" element={<QuizzesViewer />} />
            <Route path="/my-quizzes-viewer" element={<MyQuizzesViewer />} />
            <Route path="/user-scores" element={<UserScores />} />
            <Route path="/tournament-curator" element={<TournamentCurator />} />
            <Route path="/quiz-curator" element={<QuizCurator />} />
            <Route path="/tourney-results" element={<TourneyResults />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ScreenNameContext.Provider>
      </AuthModalProvider>
    </Router>
  );
}

export default App;

