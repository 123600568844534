import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faHome } from '@fortawesome/free-solid-svg-icons';
import triviaFileMapping from './quizdata/TriviaFileMapping';
import { auth, firestore } from './firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import ScreenNameContext from './ScreenNameContext';
import LoadingSpinner from './LoadingSpinner';

function TriviaScreen() {
  const { screenName } = useContext(ScreenNameContext);
  const [quizData, setQuizData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [sessionCorrectCount, setSessionCorrectCount] = useState(0);
  const [sessionIncorrectCount, setSessionIncorrectCount] = useState(0);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [userAnswers, setUserAnswers] = useState([]);
  const [questionTimeBonus, setQuestionTimeBonus] = useState([]);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [timer, setTimer] = useState(25); // 25 seconds timer
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadQuizData = async () => {
      const { quizFile, quizType, quizName, quizState } = location.state || {};

      if (!quizFile || !quizType || !quizName) {
        alert('Missing quiz information.');
        navigate('/trivia');
        return;
      }

      if (quizState) {
        // Restore quiz state
        setQuizData(quizState.quizData);
        setCurrentQuestionIndex(quizState.currentQuestionIndex);
        setSelectedAnswer(quizState.selectedAnswer);
        setSessionCorrectCount(quizState.sessionCorrectCount);
        setSessionIncorrectCount(quizState.sessionIncorrectCount);
        setCompletionPercentage(quizState.completionPercentage);
        setShuffledOptions(quizState.shuffledOptions);
        setTimer(quizState.timer);
        setUserAnswers(quizState.userAnswers || []);
      } else {
        const data = triviaFileMapping[quizFile];
        if (data && data.questions) {
          let questions = data.questions;

          if (quizType === 'randomQuiz10Timed') {
            questions = data.questions.sort(() => Math.random() - 0.5).slice(0, 10);
          }

          setQuizData({
            questions,
            quizName,
            categoryName: data.categoryName || '',
          });
        }
      }
    };

    loadQuizData();
  }, [location.state, navigate]);

  useEffect(() => {
    if (quizData) {
      const question = quizData.questions[currentQuestionIndex];
      setShuffledOptions(shuffleArray([...question.options]));
      setCorrectAnswer(question.correctAnswer);
    }
  }, [quizData, currentQuestionIndex]);

  const shuffleArray = (array) => {
    // Fisher-Yates Shuffle Algorithm
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleAnswerSelection = (answer) => {
    if (selectedAnswer || timer === 0) return;

    setSelectedAnswer(answer);
    const question = quizData.questions[currentQuestionIndex];
    const answerIsCorrect = answer === question.correctAnswer;
    setIsCorrect(answerIsCorrect);

    if (answerIsCorrect) {
      setSessionCorrectCount((prevCount) => prevCount + 1);
    } else {
      setSessionIncorrectCount((prevCount) => prevCount + 1);
    }

    // Update userAnswers
    const updatedUserAnswers = [
      ...userAnswers,
      {
        questionIndex: currentQuestionIndex,
        question: question.question,
        selectedAnswer: answer,
        correctAnswer: question.correctAnswer,
        isCorrect: answerIsCorrect,
      },
    ];
    setUserAnswers(updatedUserAnswers);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < quizData.questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedAnswer(null);
      setIsCorrect(null);
      setTimer(25); // Reset the timer for the next question
    } else {
      handleExitQuiz();
    }
  };

  const handleExitQuiz = async () => {
    const totalPoints = sessionCorrectCount * 10;
    const totalBonusPoints = questionTimeBonus.reduce((acc, bonus) => acc + bonus, 0);
    const totalScore = totalPoints + totalBonusPoints;

    try {
      const currentUser = auth.currentUser;
      if (!currentUser || !currentUser.email) {
        alert('Please log in to save your quiz results');
        navigate('/');
        return;
      }

      // Save quiz results to Firestore
      await saveQuizResultsToFirestore(totalScore);

      navigate('/trivia-summary', {
        state: {
          correctCount: sessionCorrectCount,
          incorrectCount: sessionIncorrectCount,
          completionPercentage,
          totalScore,
          totalPoints,
          totalBonusPoints,
          quizName: quizData.quizName || '', // Include quizName
          quizType: location.state.quizType || '',
          quizId: location.state.quizId || '',
          userAnswers, // Include userAnswers
          tournamentId: location.state.tournamentId || '', // Include if applicable
        },
      });
    } catch (error) {
      console.error('Error saving quiz results:', error);
      alert('Error saving quiz results. Please try again.');
    }
  };

  const saveQuizResultsToFirestore = async (totalScore) => {
    const quizType = location.state.quizType;
    const quizName = location.state.quizName;
    const quizFile = location.state.quizFile;

    const currentUser = auth.currentUser;

    if (!currentUser) {
      alert('You need to be logged in to save your quiz results.');
      return;
    }

    const userUid = currentUser.uid;
    const userEmail = currentUser.email;

    // Determine the collection name based on quizType
    let collectionName = '';

    if (quizType === 'dailyQuiz') {
      collectionName = 'dailyTrivia';
      // Additional logic for daily quiz (e.g., updating streak)
    } else if (quizType === 'randomQuiz10Timed') {
      collectionName = 'randomQuizAnyCategory10Timed';
    } else if (quizType === 'regularQuiz') {
      const categoryName = quizData.categoryName || 'General';
      collectionName = `trivia${categoryName.replace(/\s+/g, '')}`;
    } else {
      // Default collection
      collectionName = 'quizResults';
    }

    // Reference to the collection
    const quizResultsRef = collection(firestore, collectionName);

    // Save the result
    await addDoc(quizResultsRef, {
      userUid,
      userEmail,
      screenName, // Use screenName from context
      quizName,
      quizFile,
      quizType,
      score: totalScore,
      correctCount: sessionCorrectCount,
      incorrectCount: sessionIncorrectCount,
      timestamp: serverTimestamp(),
    });
  };

  const handleFlagQuestion = () => {
    navigate('/q-feedback', {
      state: {
        quizFile: location.state.quizFile,
        questionIndex: currentQuestionIndex,
        questionText: quizData.questions[currentQuestionIndex].question,
        quizState: {
          currentQuestionIndex,
          selectedAnswer,
          sessionCorrectCount,
          sessionIncorrectCount,
          completionPercentage,
          shuffledOptions,
          quizData,
          timer,
          userAnswers,
        },
      },
    });
  };

  const handleGoHome = () => {
    navigate('/landing');
  };

  if (!quizData || quizData.questions.length === 0) {
    return <LoadingSpinner />;
  }

  const question = quizData.questions[currentQuestionIndex];
  const explanationHtml = selectedAnswer ? question.explanation : '';

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav
        className="w-full text-white p-4 flex justify-between items-center"
        style={{ backgroundColor: '#9C27B0' }}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faHome}
            className="text-white text-2xl cursor-pointer"
            onClick={handleGoHome}
          />
        </div>
        <h1 className="text-xl font-bold">
          {quizData.quizName ? quizData.quizName.replace(/_/g, ' ') : 'Quiz'} - Question{' '}
          {currentQuestionIndex + 1} of {quizData.questions.length}
        </h1>
        <div></div>
      </nav>

      {selectedAnswer && (
        <button
          className="absolute top-4 right-4 bg-[#9C27B0] text-white p-2 rounded-lg cursor-pointer"
          onClick={handleFlagQuestion}
        >
          <FontAwesomeIcon icon={faFlag} />
        </button>
      )}

      <div className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-lg mt-8">
        <div
          className="text-lg font-semibold text-center mb-4"
          dangerouslySetInnerHTML={{
            __html: question.question ? question.question.replace(/\n/g, '<br/>') : '',
          }}
        />

        <div className="grid gap-4">
          {shuffledOptions.map((option, index) => (
            <div
              key={index}
              className={`bg-[#9C27B0] text-white p-4 rounded-lg text-center cursor-pointer ${
                selectedAnswer === option ? (isCorrect ? 'bg-green-600' : 'bg-red-600') : ''
              }`}
              onClick={() => handleAnswerSelection(option)}
            >
              <div
                dangerouslySetInnerHTML={{ __html: option ? option.replace(/\n/g, '<br/>') : '' }}
              />
            </div>
          ))}
        </div>

        {selectedAnswer && (
          <>
            {explanationHtml && explanationHtml.length > 0 && (
              <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-6">
                <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
              </div>
            )}
            <div className="flex justify-center mt-6">
              {currentQuestionIndex < quizData.questions.length - 1 ? (
                <button
                  className="bg-[#9C27B0] text-white p-4 rounded-lg"
                  onClick={handleNextQuestion}
                >
                  Next
                </button>
              ) : (
                <button className="bg-[#9C27B0] text-white p-4 rounded-lg" onClick={handleExitQuiz}>
                  Finish
                </button>
              )}
            </div>
            {question.url && (
              <div className="text-center mt-4">
                <a
                  href={question.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                >
                  {question.url.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default TriviaScreen;
