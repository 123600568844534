// LandingPage.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore, analytics } from './firebase';
import { setDoc, getDoc, doc, serverTimestamp } from 'firebase/firestore';
import stringSimilarity from 'string-similarity';
import quizzesData from './quizdata/quizzes.json';  
import triviaData from './quizdata/trivia.json';   
import triviaFileMapping from './quizdata/TriviaFileMapping'; 
import bannedWords from './utils/engBadWords.json';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaPen, FaTrash, FaTimesCircle, FaTimes } from 'react-icons/fa'; 
import { faLightbulb, faGraduationCap, faMagnifyingGlass, faUser, faArrowRight } from '@fortawesome/free-solid-svg-icons';  // Importing icons
import DailyQuizBanner from './DailyQuizBanner'; 
import useDailyQuiz from './useDailyQuiz'; 
import './LandingPage.css';
import ScreenNameContext from './ScreenNameContext';
import { logEvent } from 'firebase/analytics'; // Ensure logEvent is imported

function LandingPage() {
  const [learningGoal, setLearningGoal] = useState('');
  const [learningGoalInput, setLearningGoalInput] = useState(''); // Separate input state
  const [learningGoalHistory, setLearningGoalHistory] = useState([]); 
  const [user, setUser] = useState(null);
  const { screenName, setScreenName } = useContext(ScreenNameContext);
  const [isSubmitted, setIsSubmitted] = useState(false); // Check if goal was submitted
  const textareaRef = useRef(null);
  const navigate = useNavigate();

  const { dailyQuiz, streakCount, quizCompleted } = useDailyQuiz(triviaData);  
  const dailyTriviaBannerText = "Today's Daily Quiz";

  // Random Quiz logic: fetch a random quiz
  const getRandomQuiz = () => {
    const randomIndex = Math.floor(Math.random() * triviaData.sections.length);
    const randomSection = quizzesData.sections[randomIndex];
    const randomQuizIndex = Math.floor(Math.random() * randomSection.quizzes.length);
    return { ...randomSection.quizzes[randomQuizIndex], categoryName: randomSection.name };
  };

  const randomQuiz = getRandomQuiz(); // Generate a random quiz

  const handleQuizLaunch = (quiz) => {
    // Log quiz start event
    if (analytics) {
      logEvent(analytics, 'quiz_start', { quiz_name: quiz.name, category: quiz.categoryName || 'Unknown' });
    }
    navigate(`/trivia-screen`, {
      state: {
        quizFile: quiz.jsonFile,
        quizName: quiz.name,
        quizType: 'randomQuiz10Timed',  // or whichever type you intend to use
        categoryName: quiz.categoryName || '',
      },
    });
  };

  useEffect(() => {
    // Log page view event
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_path: '/landing',
        page_title: 'Landing Page',
        page_location: window.location.href,
      });
    }

    // Listen for changes in authentication state
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await fetchUserLearningData(currentUser.uid);
        await fetchUserScreenName(currentUser.uid); 
      } else {
        alert('You must be logged in to set a learning goal.');
        navigate('/');
        
        // Log navigation away due to unauthenticated access
        if (analytics) {
          logEvent(analytics, 'navigate_unauthenticated', { from: '/landing', to: '/' });
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate, analytics]);

  const fetchUserLearningData = async (userUid) => {
    try {
      const userDocRef = doc(firestore, 'learningGoals', userUid);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setLearningGoal(data.learningGoal || '');  // Set saved learning goal from Firestore
        setLearningGoalHistory(data.learningGoalHistory || []); 
        // Set isSubmitted based on whether learningGoal exists
        setIsSubmitted(!!data.learningGoal);

        // Log existing learning goal retrieval
        if (analytics) {
          logEvent(analytics, 'learning_goal_retrieved', { learning_goal: data.learningGoal || 'None' });
        }
      } else {
        // Reset if no data exists
        setLearningGoal('');
        setIsSubmitted(false);
      }
    } catch (error) {
      console.error('Error fetching learning goal data:', error);
    }
  };

  const fetchUserScreenName = async (uid) => {
    try {
      const userDocRef = doc(firestore, 'users', uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setScreenName(data.screenName || '');  
        
        // Log screen name retrieval
        if (analytics) {
          logEvent(analytics, 'screen_name_retrieved', { screen_name: data.screenName || 'None' });
        }
      }
    } catch (error) {
      console.error('Error fetching screen name:', error);
    }
  };

  const containsBannedWords = (text) => {
    const textLower = text.toLowerCase();
    const badWordPattern = new RegExp(`\\b(${bannedWords.join('|')})\\b`, 'gi');
    return badWordPattern.test(textLower);
  };

  const handleSubmit = async () => {
    if (!learningGoalInput) {
      alert('Please enter a learning goal.');
      return;
    }

    if (!user) {
      alert('You must be logged in to set a learning goal.');
      return;
    }

    if (containsBannedWords(learningGoalInput)) {
      alert("Your learning goal contains inappropriate content. Please try again.");
      return;
    }

    try {
      const learningGoalsRef = doc(firestore, 'learningGoals', user.uid);
      await setDoc(learningGoalsRef, {
        learningGoal: learningGoalInput,
        userEmail: user.email,
        userUid: user.uid,
        userScreenName: screenName || user.displayName,
        timestamp: serverTimestamp(),
        learningPlan: '', // Reset learning plan
        feedback: '', // Reset feedback
        progressPercentage: 0, // Set to 0
        quizGenerationStatus: '', // Reset quiz generation status
        triggerEvalGoalAndEnhance: true,
        }, { merge: true });

      const matchedQuizzes = findMatchingQuizzes(learningGoalInput);
      console.log("Matched Quizzes:", matchedQuizzes);

      // Log learning goal submission
      if (analytics) {
        logEvent(analytics, 'learning_goal_submit', { learning_goal: learningGoalInput, matched_quizzes_count: matchedQuizzes.length });
      }

      // Update state variables
      setLearningGoal(learningGoalInput);  // Set the saved goal
      setIsSubmitted(true); // Now mark it as submitted

      navigate('/learning-focus', {
        state: { learningGoal: learningGoalInput, matchedQuizzes }
      });

      // Log navigation to learning focus
      if (analytics) {
        logEvent(analytics, 'navigate_to_learning_focus', { learning_goal: learningGoalInput });
      }

    } catch (error) {
      console.error('Error saving learning goal:', error);
      alert('Failed to save your learning goal. Please try again later.');

      // Log learning goal submission failure
      if (analytics) {
        logEvent(analytics, 'learning_goal_submit_failure', { error_code: error.code, error_message: error.message });
      }
    }
  };

  const handleTriviaExplorer = () => {
    navigate('/trivia'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'trivia_explorer' });
    }
  }
  
  const handleQuizExplorer = () => {
    navigate('/quizzes-free'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'quiz_explorer' });
    }
  }
  
  const handleQuizSearch = () => {
    navigate('/quizzes-viewer'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'quiz_search' });
    }
  }
  
  const handleMyQuizzes = () => {
    navigate('/my-quizzes-viewer'); 
    // Log navigation event
    if (analytics) {
      logEvent(analytics, 'navigate_button_click', { destination: 'my_quizzes' });
    }
  }

  const findMatchingQuizzes = (goal, threshold = 0.42, maxResults = 5) => {
    const allQuizzes = [...quizzesData.sections, ...triviaData.sections];
    const matchingQuizzes = [];

    for (const section of allQuizzes) {
      for (const quiz of section.quizzes) {
        const quizData = triviaFileMapping[quiz.jsonFile];
        
        if (!quizData) {
          continue;
        }

        const quizName = quiz.name.toLowerCase();
        const goalLower = goal.toLowerCase();

        const nameSimilarity = stringSimilarity.compareTwoStrings(quizName, goalLower);
        const metadataSimilarity = quizData.metadata?.quizName 
          ? stringSimilarity.compareTwoStrings(quizData.metadata.quizName.toLowerCase(), goalLower)
          : 0;
        const tagsSimilarity = quizData.questions?.reduce((max, question) => {
          const questionMax = question.tags?.reduce((tagMax, tag) => {
            const tagSimilarity = stringSimilarity.compareTwoStrings(tag.toLowerCase(), goalLower);
            return Math.max(tagMax, tagSimilarity);
          }, 0) || 0;
          return Math.max(max, questionMax);
        }, 0) || 0;

        const bestMatch = Math.max(nameSimilarity, metadataSimilarity, tagsSimilarity);

        if (bestMatch >= threshold) {
          matchingQuizzes.push({
            ...quiz,
            matchScore: bestMatch
          });

          // Log quiz match found
          if (analytics) {
            logEvent(analytics, 'quiz_match_found', { quiz_name: quiz.name, match_score: bestMatch });
          }
        }
      }
    }

    matchingQuizzes.sort((a, b) => b.matchScore - a.matchScore);
    return matchingQuizzes.slice(0, maxResults);
  };

  const handleHistorySelection = (selectedGoal) => {
    navigate('/learning-focus', {
      state: { selectedGoal }, // Passing the selected learning goal as state
    });

    // Log history selection
    if (analytics) {
      logEvent(analytics, 'learning_goal_history_selected', { selected_goal: selectedGoal });
    }
  };

  const handleDeleteLearningGoal = async () => {
    if (window.confirm('Are you sure you want to delete this learning goal?')) {
      try {
        setLearningGoal('');
        setIsSubmitted(false);

        // Log learning goal deletion
        if (analytics) {
          logEvent(analytics, 'learning_goal_delete', { previous_learning_goal: learningGoal });
        }
      } catch (error) {
        console.error('Error deleting tournament:', error);
        alert('Failed to delete tournament. Please try again.');
        
        // Log deletion failure
        if (analytics) {
          logEvent(analytics, 'learning_goal_delete_failure', { error_code: error.code, error_message: error.message });
        }
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center">
      {/* Navbar */}
      <nav className="w-full text-white p-4 flex justify-between items-center" style={{ backgroundColor: '#9C27B0' }}>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faUser} className="text-white text-2xl" onClick={() => { 
            navigate('/profile');
            // Log profile navigation
            if (analytics) {
              logEvent(analytics, 'navigate_button_click', { destination: 'profile' });
            }
          }} />
          {screenName && (
            <span className="ml-2 text-lg cursor-pointer" onClick={() => { 
              navigate('/profile');
              // Log profile navigation
              if (analytics) {
                logEvent(analytics, 'navigate_button_click', { destination: 'profile' });
              }
            }}>
              {screenName}
            </span>
          )}
        </div>

        <h1 className="text-center text-xl font-bold">Quiznect</h1>
        <div></div> 
      </nav>

      {/* Conditionally render the banner or the input section */}
      {isSubmitted && learningGoal ? (
         <div className="flex items-center bg-gray-100 px-4 py-2">
         {/* Close Icon */}
         <FaTimes
           style={{ color: '#841584' }}
           className="mx-2 cursor-pointer"
           size={24}
           onClick={() => handleDeleteLearningGoal()}
         />
       
         {/* Learning Focus Box */}
         <div
           className="bg-purple-600 text-white rounded-lg p-4 my-4 flex justify-between items-center w-full max-w-2xl cursor-pointer"
           onClick={() => {
             navigate('/learning-focus', { state: { learningGoal } });
             if (analytics) {
               logEvent(analytics, 'navigate_to_learning_focus_click', { learning_goal: learningGoal });
             }
           }}
           style={{ backgroundColor: '#841584', cursor: 'pointer' }}
         >
           <div className="flex items-center">
             {/* Lightbulb Icon */}
             <FontAwesomeIcon icon={faLightbulb} className="text-white text-4xl mr-4" />
             {/* Learning Focus Text */}
             <div>
               <h2 className="text-lg md:text-xl font-bold">Learning Focus: {learningGoal}</h2>
               <p className="text-sm md:text-base">Continue your learning plan based on your goal.</p>
             </div>
           </div>
           {/* Arrow Icon */}
           <FontAwesomeIcon icon={faArrowRight} className="text-white text-4xl ml-4" />
         </div>
       </div>       
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent form submission
            handleSubmit(); // Manually handle the submit
          }}
        >
          <div className="section-container mt-10 text-center">
            <h1 className="text-2xl font-bold">What would you like to learn?</h1>
            <p className="mt-4 text-base">Tell us your learning goal, and we'll create a custom quiz plan just for you.</p>
            <textarea
                placeholder="I want to learn..."
                value={learningGoalInput}
                onChange={(e) => {
                  setLearningGoalInput(e.target.value.slice(0, 500));
                  
                  // Log learning goal input change (input length)
                  if (analytics) {
                    logEvent(analytics, 'learning_goal_input_change', { input_length: e.target.value.length });
                  }
                }}  // Limit to 500 chars
                maxLength={500}
                className="w-full p-2 mt-4 rounded-lg border border-gray-300 text-black resize-none min-h-10"
                ref={textareaRef}
                style={{ overflow: 'hidden' }}
                onInput={(e) => {
                    e.target.style.height = 'auto';
                    e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onPaste={(e) => {
                    // Wait for paste to complete, then scroll to bottom
                    setTimeout(() => {
                    if (textareaRef.current) {
                        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
                    }
                    }, 0);
                }}
            />

            <button
              type="submit"
              style={{ backgroundColor: '#841584' }}
              className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold"
              onClick={() => {
                // Log submit button click
                if (analytics) {
                  logEvent(analytics, 'submit_learning_goal_click', { learning_goal: learningGoalInput });
                }
              }}
            >
              Submit
            </button>

            {/* Dropdown for previous learning goals */}
            {learningGoalHistory && learningGoalHistory.length > 0 && (
              <div className="mt-4 text-center flex items-center justify-center space-x-4">
                <label className="text-lg font-bold">Learning Goals:</label>
                <select
                  className="w-[240px] p-2 rounded-lg border border-gray-300 text-black"
                  onChange={(e) => {
                    handleHistorySelection(e.target.value);
                    
                    // Log history selection
                    if (analytics) {
                      logEvent(analytics, 'learning_goal_history_select', { selected_goal: e.target.value });
                    }
                  }}
                >
                  <option value="">Select a goal...</option>
                  {learningGoalHistory.map((goal, index) => (
                    <option key={index} value={goal.learningGoal}>
                      {goal.learningGoal}
                    </option>
                  ))}
                </select>
              </div>
            )}

          </div>
        </form>
      )}

      {/* Section: Search & Free trivia and quizzes */}
      <div className="section-container mt-6 text-center">
        <p className="text-2xl font-bold">Explore Quizzes and Trivia</p>
        <button
          onClick={handleMyQuizzes}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faUser} className="mr-2" /> My Quizzes
        </button>
        <button
          onClick={handleQuizSearch}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" /> Quiz search
        </button>
        <button
          onClick={handleTriviaExplorer}
          style={{ backgroundColor: '#841584' }} 
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faLightbulb} className="mr-2" /> Free Trivia
        </button>
        <button
          onClick={handleQuizExplorer}
          style={{ backgroundColor: '#841584' }}
          className="mt-4 w-full text-white p-3 rounded-lg text-base font-bold flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faGraduationCap} className="mr-2" /> Free Quizzes
        </button>
      </div>

      {/* Daily Quiz Banner */}
      <DailyQuizBanner
        style={{ marginTop: -30 }}
        bannerText={dailyTriviaBannerText}
        quiz={dailyQuiz}
        streakCount={streakCount}
        quizCompleted={quizCompleted}
        onQuizStart={() => {
          handleQuizLaunch(dailyQuiz);
          
          // Log daily quiz start
          if (analytics) {
            logEvent(analytics, 'daily_quiz_start', { streak_count: streakCount });
          }
        }}
      />
    </div>
  );
}

export default LandingPage;





// todo: {/* Plan Section */}
      /*
      <div className="mt-16 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Choose Your Plan</h2>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-4">Monthly Subscription</h3>
          <p className="text-gray-700 mb-4">Get a new quiz generated every month to help you master your chosen topic.</p>
          <p className="text-2xl font-bold">$9.99/month</p>
          <button
            className="mt-4 w-full bg-green-600 text-white p-3 rounded-lg text-base font-bold"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    */