import React, { useEffect, useState } from 'react';
import { auth, firestore } from './firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function UserScores() {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserScores = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        navigate('/login');
        return;
      }

      try {
        const scoresRef = collection(firestore, 'userScores');
        const q = query(scoresRef, where('userUid', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);

        const userScores = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setScores(userScores);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching scores:', error);
        setLoading(false);
      }
    };

    fetchUserScores();
  }, [navigate]);

  if (loading) {
    return <div>Loading scores...</div>;
  }

  if (scores.length === 0) {
    return <div>No scores to display.</div>;
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Your Quiz Scores</h1>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2">Quiz Name</th>
            <th className="py-2">Score</th>
            <th className="py-2">Date</th>
          </tr>
        </thead>
        <tbody>
          {scores.map((score) => (
            <tr key={score.id}>
              <td className="py-2">{score.quizName}</td>
              <td className="py-2">{score.score}</td>
              <td className="py-2">{new Date(score.timestamp.seconds * 1000).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserScores;