import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAuxi7PShk24rF9L5aqvZxJXTA_cMkZsVU",
  authDomain: "quiznect.firebaseapp.com",
  projectId: "quiznect",
  storageBucket: "quiznect.appspot.com",
  messagingSenderId: "473044494109",
  appId: "1:473044494109:web:c166d5611f5d15079963f4",
  measurementId: "G-QZS29G0CFR"
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
let analyticsInit;
if (typeof window !== 'undefined' && !window.analyticsInitialized) {
  analyticsInit = getAnalytics(app);
}

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const analytics = analyticsInit;

